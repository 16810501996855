import React, { useState, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/outline';

type Property = {
    name: string;
    address: string;
    imageUrl: string;
    description: string;
};

type PropertyElementProps = Property & {
    onSelect: any;
};

const properties: Property[] = [
    {
        name: 'Hillrose 28',
        address: '161 E 28th Street, New York, New York',
        imageUrl: 'https://hillrose28.com/static/media/hero.cb542f1c.jpg',
        description: 'Introducing Hillrose28, a new ground-up twenty-story tower conceived from a simple idea: home is a feeling, as much as it is place. Located in Manhattan’s historic Rose Hill and adjacent to Gramercy and NoMad neighborhoods, each residence offers the unique energy of the city while offering a well-designed, convenient and truly modern living experience.',
    },
    {
        name: 'Theater House',
        address: '237 East 34th Street, New York, New York',
        imageUrl: 'https://images.squarespace-cdn.com/content/v1/5405ca70e4b0f72d40bc7c44/1410211578358-N198SA2MA8G1UAWDT82E/Nigh+view+west-1080tall.png',
        description: 'Theater House is a dramatic 22-story glass tower at the nexus of Manhattan\'s desirable Murray Hill and Kips Bay neighborhoods offering elegant and ultra-contemporary studios and one-, two- and three-bedroom homes, with wrap around glass curtain walls and private outdoor spaces. Embodying the creative vision of leading urban architecture firm C3D Architecture as well as interiors by the renounced Andres Escobar.',
    },
    {
        name: 'The Sugar Warehouse',
        address: '79 Laight Street, New York, New York',
        imageUrl: 'https://d2787ndpv5cwhz.cloudfront.net/9d810d4e97ee23256b220f4251f78c11d88e059d.jpg',
        description: 'Dominating the 225-foot block of TriBeca\'s popular Laight Street, this commanding mid-19th century building-once home to the Grocers\' Steam Sugar Refining Company-is a timeless homage to a bygone era with its sturdy, redbrick walls and first-story arched openings that once served as loading bays. Its landmark status is colorfully underscored by original iron window shutters still-visible painted sign-bands on the walls and star-shaped tie-rod ends that decorate alternate stories. While designing ultramodern duplex interiors, New York\'s premier architectural consortium has mastertully restored every exterior detail to its original grandeur.',
    },
    {
        name: 'Coral Lafayette',
        address: '80 Lafayette Street, New York, New York',
        imageUrl: 'https://live.staticflickr.com/7278/7013935713_2a5df2b4cc_h.jpg',
        description: 'Distinguishly rising amid the TriBeCa district, this 17-story former office building, built in 1915, proudly shows off original interior and exterior architectural details restored to former brilliance in 1997 during a complete renovation into residntial units. Modern and sophisticated introductions bring a comprehensive sprinkler system, fire command station, new boilers and windows to Coral Lafayette. Offering nearly unobstructed views to the east, north, and south the building houses three first-floor retail outlets while the floors above feature 257 residential units ranging from studios to five-bedroom residences.',
    },
    {
        name: '130 West',
        address: '130 West 46th Street, New York, New York',
        imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/6/6a/130_W57_St_2021_jeh.jpg',
        description: 'Opportunistically tapping into demand for hotel rooms in the ever-popular tourist mecca of Times Square, Forkosh boldly reconstruced the Leavitt Building, a former jewelry manufacturing loft, into The Muse Hotel a 200-room first-class hotel property, A keen evaluation of economic and demographic trends serve as the underpinning for this adaptive re-use and conversion project.',
    },
    {
        name: 'Electra',
        address: '354 East 91st Street, New York, New York',
        imageUrl: 'https://cdn-img-feed.streeteasy.com/nyc/image/20/426340720.jpg',
        description: 'The Electra reflects a contemporary way of life and was designed to accommodate discerning lifestyles. Each apartment was planned with a gracious eye and fitted with top quality finishes. Nearly every home at The Electra features a private outdoor terrace-perfect for a lazy afternoon or a sunset dinner for two, Ideally situated in the heart of the Upper East Side The Electra is just moments from many of New York\'s most popular and distinguished areas and attractions.',
    },
    {
        name: '201 East',
        address: '201 East 14th Street, New York, New York',
        imageUrl: 'https://images1.apartments.com/i2/MoAqbEzJ_wCYPiFID4-4wvHJ06v9WWW5JRIO2hjQY6s/111/coral-tower-new-york-ny-201-e-14th-street.jpg',
        description: 'A new vision of urban life for the modern student comes alive in this 15-story, 91-unit residential building built in 2000 that today primarily serves as a dormitory for NYU students. After acquiring and demolishing the original building in 1999 Forkosh reimagined the entire space, 8,500 square feet of retail space occupy the street level while upper floors rise into elite student housing, including outdoor balconies overlooking Third Avenue and 14th Street and a second-floor outdoor recreation area, An eye for versatility underscores other university amenities including a reception area recreation room television lounge, study lounge laundry room and administrative offices.',
    },
    {
        name: '321 Bowery',
        address: '321 Bowery Street, New York, New York',
        imageUrl: 'http://static1.squarespace.com/static/543e9e83e4b0aa98aa9ce463/5aba593588251bc5b38aa92c/5aba5935aa4a99d2a6244f62/1522161982911/2018-03-27+07.36.29-1.jpg',
        description: 'A blend of signature decorative detail and unique architectural features serve as an inspirational backdrop for the creative minds of the NYU students who call 312 Bowery home uncler the school\'s master lease, Located on the corner of Bowery and East 2nd Street in the Lower East Side this tiered six-and 12 story building contains 173 residences all with complete kitchenette§ and many with exterior balconies overlooking this popular neighborhood, A student lounge and laundry make the building an urban oasis for students.',
    },
    {
        name: 'Laroc',
        address: '636 Greenwich Street, New York, New York',
        imageUrl: 'https://i2.wp.com/nyunews.com/wp-content/uploads/2017/02/DSC_0315.jpg?fit=600%2C900&ssl=1',
        description: 'In the tony West Village, a refreshing and invigorating environment for NYU students emanates from this former eight-story warehouse, Built in 191 Laroc\'s original structure was completely gutted and renovated into residential units in 199 Today, ten-foot ceilings and large, double hung windows create a sense of space in each of the 71 residences that range from studios to two-bedrooms. An administrative office and nine special guest units make this a versatile property under master lease by NYU.',
    },
    {
        name: 'Coral Broome',
        address: '400 Broome Street, New York, New York',
        imageUrl: 'https://images1.apartments.com/i2/BDaBn5-WTCEywrNxTt4JDQ7tM65tXZ_bihB3FLwKjDE/111/broome-street-residential-college-new-york-ny-primary-photo.jpg',
        description: 'This former six-story government office building, built in soho in 1913, has been fully reimagined as a chic residential building, primarily for NYU students under the school\'s master lease, Originally converted to a residential loft building called"The Annex"in 1996 Forkosh added five floors in 1997 including 22 new apartments and a penthouse office suite bringing the total number of residences to 108. All residences feature hardwood floors mahogany woodwork, solid oak kitchen cabinetry and marble bathrooms with wainscoting. Coral Broome also boasts six commercial units on the ground floor and two subcellars containing below-grade parking and storage facilities.',
    },
    {
        name: 'New East 69',
        address: '2431-2447 East 69th Street, New York, New York',
        imageUrl: 'https://ssl.cdn-redfin.com/photo/204/mbphoto/830/genMid.371830_0.jpg',
        description: 'Stately elegance exudes from this two unit brick fownhouse regally situated at 2431-2447 East 69th St, in New York, intricate metalwork and white columns proclaim the building a timeless classic as residents and visitors make their way up side-by-side staircases, Dual balconies and spacious windowed gables harken back to the Days of horse-drawn carriages anel top hats.',
    },
    {
        name: 'Spiaggia',
        address: '9499 Collins Avenue, Surfside, Florida',
        imageUrl: 'https://exploremiamirealestate.com/wp-content/uploads/2019/04/41-1024x827.jpg',
        description: 'Just blocks from the exclusive Bal Harbour Shops Spiaggia\'s inspired residences and unparalleled amenities combine to satisfy the tastes of even the most discerning individuals. Open gourmet kitchens feature European crafted cherry wood-finished cabinets sleek stainless-steel appliance and Italian marble countertops while bamboo flooring an premium carpeting flow throughout the residences, An opulent lobby leads to a private clubroom with state-of-the-art entertainment systems, card tables and conference facilities, Outside the Mediterranean- style pool and sundeck, private poolside cabanas and p. rivate Beach Club transform luxury to a higher plane.',
    },
];

const PropertyElement = ({ name, address, imageUrl, description, onSelect }: PropertyElementProps) => {
    const onClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        onSelect();
    }, [onSelect]);

    return (
        <article className="flex flex-col lg:flex-row lg:even:flex-row-reverse lg:m-10">
            <img
                className="max-h-80 w-full object-cover object-top lg:max-h-3/4 lg:h-screen-3/4 lg:w-2/5 xl:w-1/2"
                alt={name}
                src={imageUrl}
            />

            <div className="w-full lg:w-3/5 xl:w-1/2 p-6 md:p-10">
                <h2 className="font-display text-2xl md:text-4xl">{name}</h2>

                <address className="font-body italic my-3 md:my-5">{address}</address>

                <p className="hidden md:block font-body leading-7">{description}</p>

                <a className="md:hidden font-body underline" href="/" onClick={onClick}>Learn More</a>
            </div>
        </article>
    );
};

const App = () => {
    const [selectedProperty, setSelectedProperty] = useState<Property | undefined>();

    const clearProperty = useCallback(() => {
        setSelectedProperty(undefined);
    }, [setSelectedProperty]);

    const modalClassNames = useMemo(() => {
        return classNames(
            'fixed',
            'top-0',
            'left-0',
            'right-0',
            'bottom-0',
            'p-4',
            'bg-dark',
            'bg-opacity-95',
            'transition-opacity',
            'duration-400',
            {
                'opacity-0': !selectedProperty,
                'opacity-100': !!selectedProperty,
                'pointer-events-none': !selectedProperty,
                'pointer-events-auto': !!selectedProperty,
            }
        );
    }, [selectedProperty]);

    return (
        <>
            <header className="m-6 md:m-10">
                <img className="h-10 md:h-16" alt="Forkosh Development Group" src="/forkosh-logo-white.png" />
            </header>

            <main>
                {properties.map((property: Property, index: number) =>
                    <PropertyElement key={index} onSelect={() => setSelectedProperty(property)} {...property} />
                )}

                <aside className={modalClassNames}>
                    <XIcon className="w-10 h-10 ml-auto" onClick={() => clearProperty()} />

                    <h2 className="font-display text-2xl p-2">{selectedProperty?.name}</h2>

                    <address className="font-body italic p-2">{selectedProperty?.address}</address>

                    <p className="font-body leading-7 p-2">{selectedProperty?.description}</p>
                </aside>
            </main>

            <footer className="font-body p-4 text-center">
                <small>Copyright &copy; 2021 Forkosh Development Group</small>
            </footer>
        </>
    );
};

export default App;